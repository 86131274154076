/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.nav-link {
  padding: 8px 16px;
  border-radius: 5px;
}

.nav-link:hover {
  color: white;
  background-color: var(--accent);
}
