/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

ul.links li a:hover {
  opacity: 0.8;
}

.links > * + * {
  margin-top: 0.75rem;
}

.btnSignUp {
  height: auto;
  opacity: 1;
  display: inline-flex;
  border: unset;
  font-family: inherit;
  position: static;
  border: 1px solid #e2b576 !important;
  color: #e2b576 !important;
  border-radius: 5px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 0;
  margin-right: 0;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
}

.emailInput {
  background-color: #1e242d;
  border: 1px solid #2d3542;
  color: #6f6f6f;
}
